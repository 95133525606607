<template>
	<div class="items-table-container">
		<VueElementLoading
			class="section-block"
			:active="itemsLoading"
			spinner="line-scale"
			:text="`загрузка ${itemsName.mult} ...`"
			:background-color="'rgba(255, 255, 255, .7)'"
		/>

		<el-table
			v-if="tableData.length"
			ref="multipleTable"
			:stripe="true"
			:data="tableData"
			@sort-change="handleSort"
			@selection-change="handleSelectionChange"
		>
			<!-- :cell-class-name="" -->
			<el-table-column type="selection" width="45"></el-table-column>

			<template v-for="(column, columnIndex) in tableSettings.columns">
				<!-- v-if="column.meta && column.meta.path" -->
				<el-table-column
					:key="`tableColumn-${columnIndex}`"
					:property="getProperty(column)"
					:label="column.label"
					:width="column.width || ''"
					:min-width="column.min_width || ''"
					:show-overflow-tooltip="column.overflowTooltip"
					:sortable="column.sortable ? 'custom' : false"
				>
					<template slot-scope="scope">
						<div
							v-if="column.meta && column.meta.isButton"
							class="table-button"
							@click="emitEvent(column.meta.eventName, scope.row)"
							v-text="getCellValue(scope.row, column)"
						></div>
						<div
							v-else-if="column.meta && column.meta.formFieldData"
							class="table-form-field"
						>
							<TableFormField
								v-if="activeRowIdx == scope.$index"
								ref="TableFormField"
								:row-data="{ row: scope.row, column: column }"
								:is-active="activeRowIdx == scope.$index"
								@fieldReady="payload => emitEvent('blockReady', payload)"
							/>
							<template>
								<div v-if="activeRowIdx !== scope.$index">
									{{ getCellValue(scope.row, column) }}
								</div>
							</template>
						</div>
						<template v-else>
							<div
								:class="
									column.meta && column.meta.cellClass ? column.meta.cellClass : ''
								"
							>
								<div v-html="getCellValue(scope.row, column)"></div>
								<i
									v-if="
										column.meta &&
											column.meta.icon &&
											getObjectVal(scope.row, column.meta.icon.conditionProp)
									"
									:class="`suffix-icon icomoon ${column.meta.icon.name}`"
								/>
								<img
									v-if="
										column.meta &&
											column.meta.img &&
											getObjectVal(scope.row, column.meta.img.conditionProp)
									"
									:class="`suffix-icon`"
									:src="column.meta.img.src"
								/>
							</div>
						</template>
					</template>
				</el-table-column>
			</template>

			<el-table-column
				v-if="tableSettings.operations"
				align="right"
				:width="getWidth(tableSettings.operations)"
				class="operations-column"
				label="Действия"
			>
				<template slot-scope="scope">
					<div v-show="activeRowIdx !== scope.$index">
						<el-button
							v-if="tableSettings.operations.info"
							class="action-button info-button"
							size="mini"
							type="success"
							:icon="`icomoon ${tableSettings.operations.info.icon}`"
							@click="emitEvent(tableSettings.operations.info.eventName, scope.row)"
						/>

						<el-button
							v-if="tableSettings.operations.edit"
							size="mini"
							class="action-button"
							type="success"
							icon="icomoon icon-pencil"
							@click="editItem({ rowIdx: scope.$index, row: scope.row })"
						/>
						<el-button
							v-if="tableSettings.operations.delete"
							class="action-button"
							:loading="itemsSaving"
							size="mini"
							type="danger"
							icon="icomoon icon-cross"
							@click="handleDelete(scope.$index, scope.row)"
						/>
					</div>

					<div v-show="activeRowIdx == scope.$index">
						<el-button
							v-if="tableSettings.operations.edit"
							:loading="itemsSaving"
							size="mini"
							class="action-button"
							type="success"
							icon="icomoon icon-check"
							@click="saveItem"
						/>
						<el-button
							class="action-button"
							size="mini"
							type="danger"
							icon="icomoon icon-stop"
							@click="activeRowIdx = null"
						/>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<div v-else-if="!itemsLoading" class="text-center section-block">
			{{ itemsName.mult }} не обнаружено
		</div>
	</div>
</template>

<script>
import 'element-ui/lib/theme-chalk/table.css';
import 'element-ui/lib/theme-chalk/table-column.css';

import { getValues, getCellValue, getObjectVal } from '@/helpers';

export default {
	components: {
		TableFormField: () => import('./form/TableFormField.vue'),
		ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
		ElTableColumn: () =>
			import(/* webpackChunkName: "ElTableColumn"*/ 'element-ui/lib/table-column')
	},
	props: {
		itemsName: { type: Object, required: true },
		tableData: {
			type: Array,
			default: () => []
		},
		tableSettings: {
			type: Object,
			default: () => ({})
		},
		itemsLoading: Boolean,
		itemsSaving: Boolean,
		editByAlias: Boolean
		// clearable: Boolean,
		// query: String
	},

	data() {
		return {
			selectedIds: [],
			activeRowIdx: null
		};
	},
	computed: {
		getCellValue: () => getCellValue,
		getObjectVal: () => getObjectVal
	},
	methods: {
		/*toConsole(val) {
			console.log(val);
		},*/
		getWidth(operations) {
			const count = Object.keys(operations).length;
			let width = '113';
			if (count == 2) {
				width = '130';
			} else if (count >= 3) {
				width = '147';
			}
			return width;
		},

		getProperty(column) {
			return column.meta && column.meta.sortBy ? column.meta.sortBy : column.prop;
		},

		getOrderVal(order) {
			if (!order) return null;

			if (order === 'descending') {
				return 'desc';
			} else if (order === 'ascending') {
				return 'asc';
			}
		},

		emitEvent(eventName, data) {
			this.$emit('event', eventName, data);
		},

		editItem(data) {
			// let newData = data;

			if (
				this.tableSettings.operations &&
				this.tableSettings.operations.editInTable
			) {
				this.activeRowIdx = data.rowIdx;
			} else {
				if (this.editByAlias) {
					data.id = data.row.alias;
				} else {
					data.id = data.row.id;
				}
				delete data.row;
				this.$emit('event', 'editItem', data);
			}
		},

		saveItem() {
			this.$emit('event', 'clearCollectedData');

			for (let ref of this.$refs.TableFormField) {
				ref.validateBlock();
			}
		},

		handleSelectionChange(val) {
			// console.log(val)
			const ids = getValues('id', val);

			this.selectedIds = ids;
		},

		handleDelete(index, row) {
			this.$confirm({
				title: 'Warning',
				message: `This will permanently delete ${this.itemsName.one}. Continue?`,
				confirmButtonText: 'Delete',
				showCancelButton: true,
				cancelButtonText: 'Cancel',
				iconClass: 'icomoon icon-warning',
				type: 'warning'
			})
				.then(() => {
					this.$emit('event', 'deleteItem', { id: row.id });
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: 'Delete canceled'
					});
				});
			// console.log(index, row);
		},

		handleSort({ prop, order }) {
			const data = {
				orderByColumn: order ? prop : null,
				orderByMethod: this.getOrderVal(order)
			};
			// console.log(data)
			this.$emit('event', 'setFilters', data);
		}
	}
};
</script>
